import { Pe } from './Pe';

class Doctor extends Pe {
    constructor() {
        super();
        this.baseUrl += '/doctor';
    }

    data({ fullName, labelName, idCardNo, mobile, sex, doctorStatus, currentPage, pageSize } = {}) {
        return super.get('/data', {
            fullName,
            labelName,
            idCardNo,
            mobile,
            sex,
            doctorStatus,
            currentPage,
            pageSize,
        });
    }

    getDetail({ id } = {}) {
        return super
            .get('/get', {
                id,
            })
            .then(json => {
                const res = json.data.data;
                const { fileBasePath, avatar } = res;
                if (fileBasePath) {
                    if (avatar) {
                        res.avatar = avatar.split(',').reduce((prev, curr) => {
                            prev.push({
                                name: curr,
                                address: fileBasePath + curr,
                            });
                            return prev;
                        }, []);
                    } else {
                        res.avatar = [];
                    }
                }

                return json;
            });
    }

    save({
        id,
        avatar,
        fullName,
        labelName,
        idCardNo,
        mobile,
        sex,
        birthday,
        graduatedSchool,
        jobTitle,
        personalIntroduction,
        workExperience,
        educationalExperience,
        reservationPrice,
        doctorStatus,
        remarks,
    } = {}) {
        let avatarUrl;
        if (avatar && avatar.length) {
            avatarUrl = avatar
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
        }
        return super.post('/save', {
            id,
            avatar: avatarUrl,
            fullName,
            labelName,
            idCardNo,
            mobile,
            sex,
            birthday,
            graduatedSchool,
            jobTitle,
            personalIntroduction,
            workExperience,
            educationalExperience,
            reservationPrice,
            doctorStatus,
            remarks,
        });
    }

    delete({ ids } = {}) {
        return super.post('/delete', {
            ids,
        });
    }
}
export default new Doctor();
export { Doctor };
